import React, { useState, useEffect } from "react"
import { Row, Container, Col, Breadcrumb } from "react-bootstrap"
import { Link, navigate } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const uri = "/camping-cookbook/"

const CampingCookbookPage = ( { location } ) => {
  const searchQuery = location.state?.searchQuery;
  const [countDown, setCountDown] = useState(9);
  
  useEffect(() => {
    const onTimeOut = () => {
      console.log('countDown='+countDown);
      if (countDown > 1) {
        setCountDown(countDown - 1)
      } else {
        navigate("https://www.iloveitcamping.com/cookbook/")
      }
    };
    setTimeout(onTimeOut, 1000);
  }, [countDown]);

  return (
  <Layout pageInfo={{ pageName: "camping-cookbook", searchQuery: searchQuery }}>
    <Seo title="Camping Cookbook" keywords={[`cookbook`, `recipes`]} description="The best online camping cookbook!" 
        imageSrc="/campfire-896196_1280.jpg" imageWidth="1280" imageHeight="854" imageAlt="Cooking at Camp" url={uri} />

      <Container fluid className="text-center">
      <Row className="justify-content-left my-3">
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/cookbooks">Cookbooks</Breadcrumb.Item>
          <Breadcrumb.Item active>Camping Cookbook</Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Row>
      <Col className="pt-2">
        <h1>Our Camping Cookbook has moved!</h1>
      </Col>
      </Row>
      <Row className="justify-content-center flex">
        <div className="lg-font">You will be forwarded in {countDown} seconds, or click to proceed:<br/>
        <Link to="https://www.iloveitcamping.com/cookbook">www.iloveitcamping.com/cookbook</Link>
        </div>
       </Row>
     
    </Container>
  </Layout>
)
}


export default CampingCookbookPage

